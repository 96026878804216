import React, { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';

import { useUser } from '../hooks/use-user.hook';
import { checkValidToken } from '../services/users.service';
import { setIsValidateToken } from '../states/authentication.slice';

import './layout.component.scss';
import { Notification } from './notification.component'

export const SecureLayout = ({ children, disableSecureLayout, ...rest }) => {
    const dispatch = useDispatch();

    const { navigate } = useI18next();
    const { loggedIn, user } = useUser();
    const [ showNotification, setShowNotification ] = useState(false);

    
    useEffect(() => {
        if (user && user.accessToken) {
            checkValidToken(user.accessToken)
              .then(res => {
                dispatch(setIsValidateToken(res.data));
              })
              .catch(error => console.error(error));
        }
    }, []);

    useEffect(() => {
        if(!loggedIn && disableSecureLayout === undefined) {
            navigate('/account/register');
        }
        if (user && !user.emailVerified && disableSecureLayout === undefined) {
            navigate('/account/verify');
        }
    }, [ loggedIn, disableSecureLayout ]);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath) {
            if (currentPath === '/' || 
            currentPath === '/features' || currentPath === '/features/' || 
            currentPath === '/faqs' || currentPath === '/faqs/' || 
            currentPath === '/corporations' || currentPath === '/corporations/' || 
            currentPath === '/corporations/faqs' || currentPath === '/corporations/faqs/' || 
            currentPath === '/corporations/features' || currentPath === '/corporations/features/' ) {
                setShowNotification(true);
            }
        }
        return () => setShowNotification(false);
    }, [ ]);


    return (
        <>
            {/* {showNotification && <Notification />} */}
            <div {...rest}>
                {(loggedIn || disableSecureLayout) && children}
            </div>
        </>
    );
};