export const getFullNameKanji = patient => {
    if (!patient) return 'N/A';
    if (!patient.lastName || !patient.firstName) return `${patient.lastNameJa} ${patient.firstNameJa}`;
    return `${patient.lastName} ${patient.firstName}`;
}

export const getFullNameKatakana = patient => {
    if (!patient) return 'N/A';
    if (!patient.lastNameJa || !patient.firstNameJa) return 'N/A';
    return `${patient.lastNameJa} ${patient.firstNameJa}`;
}