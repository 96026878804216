import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientsAsync } from '../states/patients.slice';

export const useDefaultUser = () => {
    const dispatch = useDispatch();
    const { loggedIn } = useSelector(s => s.authentication);
    const { defaultUser } = useSelector(s => s.patients);

    
    useEffect(() => {
        if (loggedIn && !defaultUser) {
            dispatch(fetchPatientsAsync());
        }
    }, [ defaultUser ]);

    return { defaultUser };
}